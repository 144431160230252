import { useState } from 'react';

import Menu from './Menu';
import StickyMenu from './StickyMenu';

export default function Header(props) {
  const {
    big,
    logo,
    links,
    light,
    icons,
    login,
    logout,
    sitemap,
    showLogin,
    setShowLogin,
    onLoginClick,
    isUserLoggedIn,
  } = props;

  // SET the local states
  const [showSitemap, setShowSitemap] = useState(false);

  // The HEADER is mainly composed by two sub-components:
  return (
    <>
      {/* A regular menu visible at the top of the page
       */}
      <Menu
        big={big}
        logo={logo}
        icons={icons}
        links={links}
        light={light}
        logout={logout}
        onProfileClick={onLoginClick}
        onSitemapClick={() => setShowSitemap(true)}
      />

      {/* A sticky menu that appears when the user scrolls down
       */}
      <StickyMenu
        logo={logo.dark}
        sitemap={sitemap}
        login={login}
        isUserLoggedIn={isUserLoggedIn}
        showLogin={showLogin}
        setShowLogin={setShowLogin}
        showSitemap={showSitemap}
        setShowSitemap={setShowSitemap}
        icons={{ menu: icons.menu.dark, profile: icons.profile.dark, close: icons.close.dark }}
      />
    </>
  );
}

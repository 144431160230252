import { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router';

import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import { PERSONAL_AREA_PATHNAME } from '../../../routes';

export default function Login(props) {
  const { login, icons, close, isUserLoggedIn } = props;

  const navigate = useNavigate();

  // SET the local states
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // NAVIGATE to the customer personal area once users log in
  useEffect(() => {
    if (isUserLoggedIn) {
      close();
      navigate(PERSONAL_AREA_PATHNAME);
    }
  }, [isUserLoggedIn]);

  // SUBMIT the login form if both fields are filled
  async function submitForm() {
    if (!username || !password) return;

    setLoading(true);
    const outcome = await login({ username, password });
    setLoading(false);

    if (outcome?.error) {
      setError(true);
    }
  }

  return (
    <form>
      <Box mr={2} height="20px" textAlign="right" sx={{ cursor: 'pointer' }} onClick={close}>
        <img height="100%" src={icons.close} alt="Close" />
      </Box>

      <Box margin="auto" maxWidth={360}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box width="28px">
            <img width="100%" src={icons.profile} alt="Profile" />
          </Box>

          <Box>
            <Typography variant="h5">Willkommen zurück!</Typography>
            <Typography fontSize="14px" fontWeight={300}>
              Bitte melden Sie sich mit Ihren Zugangsdaten an
            </Typography>
          </Box>
        </Box>

        <Stack my={2} spacing={2}>
          <TextField
            fullWidth
            size="small"
            placeholder="Email"
            sx={{ '& fieldset': { border: 'none' } }}
            slotProps={{
              input: { sx: { bgcolor: '#FFF' } },
            }}
            onChange={(e) => setUsername(e.target.value)}
          />

          <TextField
            fullWidth
            size="small"
            type="password"
            placeholder="Passwort"
            sx={{ '& fieldset': { border: 'none' } }}
            slotProps={{
              input: { sx: { bgcolor: '#FFF' } },
            }}
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && (
            <Stack direction="row" spacing={1}>
              <WarningAmberOutlinedIcon />
              <Typography fontSize="14px">
                Emailadresse oder Passwort sind nicht korrekt.
              </Typography>
            </Stack>
          )}
        </Stack>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link to="/request-password-reset" onClick={close}>
            <Typography fontSize="14px">Passwort vergessen? &gt;</Typography>
          </Link>

          <Button
            type="submit"
            size="small"
            disabled={loading}
            onClick={submitForm}
            onSubmit={submitForm}
            sx={{ textTransform: 'capitalize' }}
          >
            <Typography variant="h5" display="flex" gap={1} alignItems="center" flexDirection="row">
              Login {loading ? <CircularProgress size={20} color="inherit" /> : <>&gt;</>}
            </Typography>
          </Button>
        </Box>
      </Box>
    </form>
  );
}

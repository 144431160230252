import { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import Login from './Login';
import Sitemap from './Sitemap';

export default function StickyMenu(props) {
  const {
    logo,
    icons,
    login,
    sitemap,
    isUserLoggedIn,
    showLogin,
    setShowLogin,
    showSitemap,
    setShowSitemap,
  } = props;

  const theme = useTheme();

  const width = 280;
  const bgcolor = alpha(theme.palette.purple.main, 0.95);

  // SET the local states
  const [scrollY, setScrollY] = useState(0);

  // LISTEN to scroll event to show the menu when the user scroll reaches a certain point
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const showStickyMenu = scrollY > 170;

  const openLogin = () => {
    setShowSitemap(false);
    setShowLogin(true);
  };

  const openSitemap = () => {
    setShowLogin(false);
    setShowSitemap(true);
  };

  return (
    <Box position="fixed" top={0} zIndex={100}>
      {showLogin && (
        <Box p={2} pb={4} width="100vw" bgcolor={bgcolor}>
          <Login
            icons={icons}
            login={login}
            isUserLoggedIn={isUserLoggedIn}
            close={() => setShowLogin(false)}
          />
        </Box>
      )}

      {showSitemap && (
        <Box p={2} pb={4} width="100vw" bgcolor={bgcolor}>
          <Sitemap icons={icons} sitemap={sitemap} close={() => setShowSitemap(false)} />
        </Box>
      )}

      {(showStickyMenu || showLogin || showSitemap) && (
        <Box
          py={1}
          px={3}
          width={width}
          bgcolor={bgcolor}
          // KEEP the Menu fixed at the top of the screen
          position="fixed"
          left={`calc(calc(100vw / 2) - ${width / 2}px)`}
          // ALIGN Meu items center aligned
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <img height={22} src={logo} alt="OPIK" />

          <Box height="18px" sx={{ cursor: 'pointer' }} onClick={openSitemap}>
            <img height="100%" src={icons.menu} alt="Menu" />
          </Box>

          <Box height="26px" sx={{ cursor: 'pointer' }} onClick={openLogin}>
            <img height="100%" src={icons.profile} alt="Profile" />
          </Box>
        </Box>
      )}
    </Box>
  );
}

import { Box } from '@mui/material';

import cookieIcon from './cookie.svg';

export default function CookieFab({ onClick }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 999,
        bottom: '24px',
        left: '12px',
        width: '38px',
        height: '38px',
        borderRadius: '50%',
        backgroundColor: '#18181a',
        cursor: 'pointer',
        animation: '2.5s ease infinite alternate bounceIcon',
      }}
      onClick={onClick}
    >
      <img
        src={cookieIcon}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '60%',
          height: 'auto',
          transform: 'translate(-50%, -50%)',
        }}
        alt="cookie icon"
      />
    </Box>
  );
}
